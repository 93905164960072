<template>
    <div>
        <div class="header">
            <keep-alive>
                <agent-topbar-comp></agent-topbar-comp>
            </keep-alive>
        </div>
        <div class="container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import AgentTopbarComp from "../../components/agent/AgentTopbarComp";
    export default {
        name: "Agent",
        components: {AgentTopbarComp, OnlineUserComp, TodayStatisticComp},
        created(){
        },
    }
</script>

<style scoped>
    @import url("../../assets/css/agent.css");
</style>